import { gql } from '@apollo/client';

export const ADD_CONTACT_SUBMISSION = gql`
  mutation AddContactSubmission(
    $email: String!
    $name: String!
    $message: String!
    $type: ContactSubmissionType!
    $gRecaptchaToken: String!
  ) {
    addContactSubmission(
      email: $email
      name: $name
      message: $message
      type: $type
      gRecaptchaToken: $gRecaptchaToken
    ) {
      ok
    }
  }
`;
