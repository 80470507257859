import Head from 'next/head';
import React from 'react';
import { User, Search, List } from 'react-feather';
import Body from 'components/common/body';
import EnvLabel from 'components/common/environment/label';
import Heading from 'components/common/heading';
import Link from 'components/common/link';
import type { LayoutProps } from 'components/layouts/types';
import Logo from 'components/logo';
import { makePageTitle } from 'constants/strings';
import { HEADER_HEIGHT } from 'constants/style';
import {
  BUSINESS_HOME_URL,
  EXPLORE_URL,
  FEED_URL,
  HOME_URL,
  PROFILE_URL,
  SIGNIN_URL,
} from 'constants/urls';
import { useStaticRouter, pathnameIncludes } from 'utils/useStaticRouter';
import { HeaderContentContainer, Footer, Header, Wrapper, Nav } from '../styles';

type PublicLayoutProps = LayoutProps & {
  children: React.ReactNode;
  displayJoinButtonInsteadOfMenu?: boolean;
  referralLink?: string;
};

const PublicLayout: React.FC<PublicLayoutProps> = ({
  children,
  title,
  displayJoinButtonInsteadOfMenu,
  referralLink,
}) => {
  const router = useStaticRouter();
  const localPathnameIncludes = (pathname: string) => pathnameIncludes(router, pathname);
  const makeCopyrigthText = () => `Mihai Anton © | ${new Date().getFullYear()}`;
  const isMainPage = localPathnameIncludes(HOME_URL) || localPathnameIncludes(BUSINESS_HOME_URL);
  const navItemHeadingLevel = 400;

  return (
    <>
      <Head>
        <title>{makePageTitle(title)}</title>
      </Head>
      <Wrapper>
        <HeaderContentContainer
          position={isMainPage ? 'relative' : undefined} // on / we want the header to scroll up with the page
          paddingTop={isMainPage ? undefined : HEADER_HEIGHT}
        >
          <Header
            showBackgroundEffects={!isMainPage}
            position={isMainPage ? 'absolute' : 'fixed'} // on / we want the header to be in the image
          >
            {/* Keep the link so flex pushes the nav to the right even though the logo is absent */}
            <Link href={HOME_URL}>{!isMainPage && <Logo skipBlur />}</Link>

            <Nav>
              <EnvLabel />

              {displayJoinButtonInsteadOfMenu ? (
                <Link href={referralLink || SIGNIN_URL}>
                  <Heading noWrap light={isMainPage} level={navItemHeadingLevel}>
                    Join Idealist!
                  </Heading>
                </Link>
              ) : (
                <>
                  <Link href={FEED_URL}>
                    <Heading
                      light={isMainPage}
                      selected={localPathnameIncludes(FEED_URL)}
                      level={navItemHeadingLevel}
                    >
                      <List />
                    </Heading>
                  </Link>
                  <Link href={EXPLORE_URL}>
                    <Heading
                      light={isMainPage}
                      selected={localPathnameIncludes(EXPLORE_URL)}
                      level={navItemHeadingLevel}
                    >
                      <Search />
                    </Heading>
                  </Link>
                  <Link href={PROFILE_URL}>
                    <Heading
                      light={isMainPage}
                      selected={localPathnameIncludes(PROFILE_URL)}
                      level={navItemHeadingLevel}
                    >
                      <User />
                    </Heading>
                  </Link>
                </>
              )}
            </Nav>
          </Header>
          {children}
        </HeaderContentContainer>
        <Footer>
          <Body>{makeCopyrigthText()}</Body>
        </Footer>
      </Wrapper>
    </>
  );
};

export default PublicLayout;
